import React from "react"

// Theme
const theme = {
  fontFamilyRegular: "04b_03",
  fontFamilyBold: "04b_11",
  fontFamilyItalic: "Handy",
  fontFamilyHeader: "04b_24",
  spacer: 8,
  fontSize: 16,
  lineHeight: 1.25,
  border: 2,
  unit: "px",
  primaryColor: [
    "#692400",
    "#823600",
    "#9B4B00",
    "#B46300",
    "#CD7D00",
    "#E69A01",
    "#FFB904",
    "#FFC018",
    "#FFC82D",
    "#FFCE44",
    "#FFD55B",
    "#FFDC71",
    "#FFE288",
    "#FFE89F",
    "#FFEEB5",
    "#FFF3CC",
  ],
  backgroundColor: [
    "#191910",
    "#211F13",
    "#282516",
    "#302B19",
    "#39311B",
    "#41361D",
    "#4A3B1F",
    "#4F3E20",
    "#544220",
    "#584521",
    "#5D4822",
    "#614B23",
    "#664F24",
    "#6A5225",
    "#6E5526",
    "#735827",
  ],
  foregroundColor: [
    "#692400",
    "#823600",
    "#9B4B00",
    "#B46300",
    "#CD7D00",
    "#E69A01",
    "#FFB904",
    "#FFC018",
    "#FFC82D",
    "#FFCE44",
    "#FFD55B",
    "#FFDC71",
    "#FFE288",
    "#FFE89F",
    "#FFEEB5",
    "#FFF3CC",
  ],
}
const ThemeContext = React.createContext(theme)

class ThemeProvider extends React.Component {
  componentWillMount() {}

  render() {
    const { children } = this.props
    // Fonts
    const { fontFamilyRegular } = this.theme
    const { fontFamilyBold } = this.theme
    const { fontFamilyItalic } = this.theme
    const { fontFamilyHeader } = this.theme
    // Theme
    const { spacer } = this.theme
    const { fontSize } = this.theme
    const { lineHeight } = this.theme
    const { border } = this.theme
    const { unit } = this.theme
    // Colors
    const { primaryColor } = this.theme
    const { backgroundColor } = this.theme
    const { foregroundColor } = this.theme
    return (
      <ThemeContext.Provider
        value={{
          spacer,
          // Fonts
          fontFamilyRegular,
          fontFamilyBold,
          fontFamilyItalic,
          fontFamilyHeader,
          // Theme
          fontSize,
          lineHeight,
          border,
          unit,
          // Colors
          primaryColor,
          backgroundColor,
          foregroundColor,
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }
