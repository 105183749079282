import React from "react"
import styled from "@emotion/styled"
import ThemeContext from "../context/theme-context"

const PixelBorder = props => (
  <ThemeContext.Consumer>
    {theme => (
      <PixelBorderContent theme={theme} props={props}>
        {props.children}
      </PixelBorderContent>
    )}
  </ThemeContext.Consumer>
)

const PixelBorderContent = styled.div`
  border: ${props => props.theme.border + props.theme.unit} solid
    ${props => props.theme.primaryColor[4]};
  padding: ${props => props.theme.spacer * 2 + props.theme.unit};
  margin-bottom: ${props => props.theme.border + props.theme.unit};
`

export default PixelBorder
