import React from "react"
import styled from "@emotion/styled"
import ThemeContext from "../context/theme-context"
import PropTypes from "prop-types"

const Shortcut = ({ onClick, title, active }) => (
  <ThemeContext.Consumer>
    {theme => (
      <ShortcutContent
        onClick={e => {
          e.preventDefault()
          if (onClick) {
            onClick()
          }
        }}
        className={`${active ? "active" : ""}`}
        theme={theme}
      >
        <div className="icon">
          <svg
            width="64"
            height="64"
            className="img"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 12 7"
            style={{ enableBackground: "new 0 0 12 7" }}
            xmlSpace="preserve"
          >
            <path
              shapeRendering="crispEdges"
              d="M7,0h2v2h1V0h2v2h-1v1h-1v1h1v3H9V5H8v2H6V5h1V4h1V3H7V0z M4,3v1h1v3H0V5h1V4h1V3
                h1V1H2v1H0V1h1V0h4v3H4z M3,5H2v1h1V5z"
            />
          </svg>
        </div>
        <div className="title">
          <span>{title}</span>
        </div>
      </ShortcutContent>
    )}
  </ThemeContext.Consumer>
)

Shortcut.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
}

const ShortcutContent = styled.div`
  padding: ${props => props.theme.spacer * 0.5 + props.theme.unit};
  cursor: pointer;
  width: ${props => props.theme.spacer * 12 + props.theme.unit};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  word-wrap: break-word;

  > * {
    user-select: none;
  }
  .icon {
    width: ${props => props.theme.spacer * 8 + props.theme.unit};
    height: ${props => props.theme.spacer * 8 + props.theme.unit};
    svg {
      width: ${props => props.theme.spacer * 8 + props.theme.unit};
      height: ${props => props.theme.spacer * 8 + props.theme.unit};
      fill: ${props => props.theme.primaryColor[4]};
    }
  }
  .title {
    width: 100%;
    text-decoration: none;
    span {
      background: ${props => props.theme.backgroundColor[7]};
      color: ${props => props.theme.primaryColor[6]};
    }
  }
  :hover {
    background: ${props => props.theme.backgroundColor[5]};
  }
  &.active,
  &:active {
    .title span {
      background: ${props => props.theme.primaryColor[7]};
      color: ${props => props.theme.backgroundColor[0]};
    }
    .icon svg {
      fill: ${props => props.theme.primaryColor[5]};
    }
  }
`

export default Shortcut
