import React from "react"
import styled from "@emotion/styled"
import ThemeContext from "../context/theme-context"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { toggleWindow } from "../state/app"

const WindowClose = ({ windows, toggleWindow, id, children }) => (
  <div className="action-item" onClick={() => toggleWindow(id)}>
    {children}
  </div>
)

export default connect(
  state => ({ isDrawerOpen: state.app.windows }),
  dispatch => ({ toggleWindow: id => dispatch(toggleWindow(id)) })
)(WindowClose)

WindowClose.propTypes = {
  id: PropTypes.string,
}

WindowClose.defaultProps = {
  id: "none",
}
