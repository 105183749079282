import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Rnd } from "react-rnd"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { Global, css } from "@emotion/core"
import { toggleDrawer, toggleWindow } from "../state/app"

import ThemeContext from "../context/theme-context"
import Notices from "../components/notices"
import Window from "../components/window"
import Shortcut from "../components/shortcut"
import ShortcutList from "../components/shortcut-list"
import PixelBorder from "../components/pixel-border"
import LightSwitch from "./light-switch"

import Chess from "../components/chess"

import TestPage from "../components/test"

function DoesThisWork(windows, id) {
  let found = windows.find(window => window.id === id)
  if (found) {
    return found.active
  } else {
    return false
  }
}

const makeGlobalStyles = theme => css`
  body,
  html {
    // Scrollbar
    &::-webkit-scrollbar {
      width: ${theme.spacer * 1 + theme.unit};
    }
    & {
      scrollbar-width: thin;
      scrollbar-color: ${theme.primaryColor[6]} ${theme.backgroundColor[15]};
    }
    &::-webkit-scrollbar-track {
      background: ${theme.backgroundColor[15]};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.primaryColor[6]};
    }
  }
`

const Layout = ({
  location,
  title,
  children,
  isDrawerOpen,
  isDarkMode,
  toggleDrawer,
  toggleWindow,
  windows,
}) => (
  <ThemeContext.Consumer>
    {theme => (
      <Wrapper theme={theme}>
        <Global styles={makeGlobalStyles(theme)} />
        <Menu theme={theme}>
          <Link to={`/`} className="logo">
            <svg
              className="img"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 12 7"
              style={{ enableBackground: "new 0 0 12 7" }}
              xmlSpace="preserve"
            >
              <path
                shapeRendering="crispEdges"
                style={{ fill: "#000000" }}
                d="M7,0h2v2h1V0h2v2h-1v1h-1v1h1v3H9V5H8v2H6V5h1V4h1V3H7V0z M4,3v1h1v3H0V5h1V4h1V3
                h1V1H2v1H0V1h1V0h4v3H4z M3,5H2v1h1V5z"
              />
            </svg>
          </Link>
          <LightSwitch></LightSwitch>
          <div
            className="nav-primary"
            style={isDrawerOpen ? { opacity: 1 } : { opacity: 0.6 }}
            onClick={() => toggleDrawer(!isDrawerOpen)}
          >
            Drawer
          </div>
          <div
            className="nav-primary"
            style={
              DoesThisWork(windows, "Braxtronix")
                ? { opacity: 1 }
                : { opacity: 0.6 }
            }
            onClick={() => toggleWindow("Braxtronix")}
          >
            Braxtronix
          </div>
          <div
            className="nav-primary"
            style={
              DoesThisWork(windows, "Test") ? { opacity: 1 } : { opacity: 0.6 }
            }
            onClick={() => toggleWindow("Test")}
          >
            Test
          </div>
        </Menu>
        <Notices />

        <div
          className="desktop"
          style={{
            padding: `
                  ${theme.spacer * 4 + theme.unit}
                  ${theme.spacer * 2 + theme.unit}
                  ${theme.spacer * 2 + theme.unit}
                  ${theme.spacer * 2 + theme.unit}`,
          }}
        >
          <ShortcutList>
            <Shortcut
              title="Braxtronix.pizza"
              onClick={() => toggleWindow("Braxtronix")}
              active={DoesThisWork(windows, "Braxtronix")}
            ></Shortcut>
            <Shortcut
              title="Test.tst"
              onClick={() => toggleWindow("Test")}
              active={DoesThisWork(windows, "Test")}
            ></Shortcut>
            <Shortcut
              title="Type Test"
              onClick={() => {
                toggleWindow("TypeTest")
              }}
              active={DoesThisWork(windows, "TypeTest")}
            ></Shortcut>
            <Shortcut
              title="Chess"
              onClick={() => {
                toggleWindow("Chess")
              }}
              active={DoesThisWork(windows, "Chess")}
            ></Shortcut>
          </ShortcutList>
          <WindowContainer>
            <Window
              title="Braxtronix"
              id="Braxtronix"
              x={theme.spacer * 14}
              y={theme.spacer * 4}
              width={theme.spacer * 64}
              height={theme.spacer * 64}
              z={1000}
              open={DoesThisWork(windows, "Braxtronix")}
            >
              <main>{children}</main>
            </Window>
            <Window
              title="Type Test"
              id="TypeTest"
              x={theme.spacer * 16}
              y={theme.spacer * 6}
              width={theme.spacer * 64}
              height={theme.spacer * 64}
              z={1000}
              open={DoesThisWork(windows, "TypeTest")}
            >
              <TestPage></TestPage>
            </Window>
            <Window
              title="Chess"
              id="Chess"
              x={theme.spacer * 80}
              y={theme.spacer * 4}
              width={theme.spacer * 36}
              height={theme.spacer * 64}
              z={1000}
              open={DoesThisWork(windows, "Chess")}
            >
              <Chess></Chess>
            </Window>
            <Window
              title="Another Testt"
              id="Test"
              x={theme.spacer * 80}
              y={theme.spacer * 4}
              width={theme.spacer * 32}
              height={theme.spacer * 64}
              z={1000}
              open={DoesThisWork(windows, "Test")}
            >
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
              This is a test
              <br />
            </Window>
          </WindowContainer>
          <div
            style={{
              opacity: 0.4,
              width: theme.spacer * 18 + theme.unit,
              padding: `${theme.spacer * 5 + theme.unit} 0`,
            }}
          >
            {windows.map(window => {
              return (
                <PixelBorder key={window.id + "_PixelBorder"}>
                  <div>{window.id}</div>
                  <div>{window.active ? "true" : "false"}</div>
                  <div>
                    {DoesThisWork(windows, window.id) ? "true" : "false"}
                  </div>
                </PixelBorder>
              )
            })}
            <PixelBorder>
              <div>isDarkMode:</div>
              <div>{isDarkMode ? "true" : "false"}</div>
            </PixelBorder>
            <PixelBorder>
              <div>isDrawerOpen:</div>
              <div>{isDrawerOpen ? "true" : "false"}</div>
            </PixelBorder>
          </div>
        </div>

        <Footer theme={theme}>
          &copy; {new Date().getFullYear()} by Braxtronix
        </Footer>
      </Wrapper>
    )}
  </ThemeContext.Consumer>
)

const WindowContainer = styled.div`
  @media (min-width: 720px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
`
const Wrapper = styled.div`
  width: 100%;
  font-family: "${props => props.theme.fontFamilyRegular}";
  font-size: ${props => props.theme.fontSize * 1 + props.theme.unit};
  line-height: ${props => props.theme.lineHeight};
  color: ${props => props.theme.foregroundColor[6]};
  background: ${props => props.theme.backgroundColor[1]};
  min-height: calc(
    100vh - ${props => props.theme.spacer * 6 + props.theme.unit}
  );
  @media (min-width: 720px) {
    overflow: hidden;
    position: relative;
    padding-bottom: ${props => props.theme.spacer * 6 + props.theme.unit};
    height: calc(
      100vh - ${props => props.theme.spacer * 6 + props.theme.unit}
    );
  }

  // Typography
  a,
  a:link,
  a:visited {
    color: ${props => props.theme.primaryColor[6]};
    background: ${props => props.theme.backgroundColor[6]};
    text-decoration: none;
    :hover {
      color: ${props => props.theme.primaryColor[12]};
    }
  }
  strong, b {
    font-family: "${props => props.theme.fontFamilyBold}";
  }
  em, i {
    font-family: "${props => props.theme.fontFamilyItalic}";
  }
  h1 {
    font-family: "${props => props.theme.fontFamilyHeader}";
    font-size: ${props => props.theme.fontSize * 3 + props.theme.unit};
    line-height: ${props => props.theme.lineHeight};
    margin-bottom: ${props => props.theme.spacer * 2 + props.theme.unit};
  }
  h2 {
    font-family: "${props => props.theme.fontFamilyHeader}";
    font-size: ${props => props.theme.fontSize * 3 + props.theme.unit};
    line-height: ${props => props.theme.lineHeight};
    margin-bottom: ${props => props.theme.spacer * 2 + props.theme.unit};
  }
  h3 {
    font-family: "${props => props.theme.fontFamilyHeader}";
    font-size: ${props => props.theme.fontSize * 2 + props.theme.unit};
    line-height: ${props => props.theme.lineHeight};
    margin-bottom: ${props => props.theme.spacer * 2 + props.theme.unit};
  }
  h4 {
    font-family: "${props => props.theme.fontFamilyHeader}";
    font-size: ${props => props.theme.fontSize * 1 + props.theme.unit};
    line-height: ${props => props.theme.lineHeight};
    margin-bottom: ${props => props.theme.spacer * 1 + props.theme.unit};
  }
  p {
    font-size: ${props => props.theme.fontSize * 1 + props.theme.unit};
    line-height: ${props => props.theme.lineHeight};
    margin-bottom: ${props => props.theme.spacer * 2 + props.theme.unit};
  }
  hr {
    border-color: ${props => props.theme.primaryColor[6]};
  }
`
const Menu = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: stretch;
  // transform: rotate(90deg) translateY(-100%);
  // transform-origin: top left;
  min-height: ${props => props.theme.spacer * 4 + props.theme.unit};
  .logo {
    display: inline-flex;
    background: ${props => props.theme.primaryColor[6]} !important;
    color: ${props => props.theme.backgroundColor[3]} !important;
    padding: ${props => props.theme.spacer * 0.5 + props.theme.unit};
    .img {
      height: ${props => props.theme.spacer * 3 + props.theme.unit};
      margin: 0;
      padding: 0;
    }
  }
  .nav-primary,
  a.nav-primary {
    text-transform: uppercase;
    display: inline-block;
    font-size: ${props => props.theme.fontSize * 1 + props.theme.unit};
    line-height: ${props => props.theme.spacer * 4 + props.theme.unit};
    padding: 0 ${props => props.theme.spacer * 2 + props.theme.unit};
    cursor: pointer;
    white-space: nowrap;
    color: ${props => props.theme.foregroundColor[6]};
    background: ${props => props.theme.backgroundColor[3]};
    &:hover {
      color: ${props => props.theme.foregroundColor[12]};
      background: ${props => props.theme.backgroundColor[12]};
    }
  }
  .nav-secondary,
  a.nav-secondary {
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    font-size: ${props => props.theme.fontSize * 1 + props.theme.unit};
    padding: ${props => props.theme.spacer * 1 + props.theme.unit}
      ${props => props.theme.spacer * 2 + props.theme.unit};
    margin-left: auto;
    cursor: pointer;
    white-space: nowrap;
    color: ${props => props.theme.foregroundColor[10]};
    background: ${props => props.theme.backgroundColor[6]};
    &:hover {
      color: ${props => props.theme.foregroundColor[12]};
      background: ${props => props.theme.backgroundColor[12]};
    }
  }
`

const Footer = styled.footer`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacer * 2 + props.theme.unit};
  color: ${props => props.theme.foregroundColor[2]};
  font-size: ${props => props.theme.fontSize * 1 + props.theme.unit};
  whitespace: nowrap;
  @media (min-width: 720px) {
    position: absolute;
    bottom: 0;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(
  state => ({
    isDarkMode: state.app.isDarkMode,
    isDrawerOpen: state.app.isDrawerOpen,
    windows: state.app.windows,
  }),
  dispatch => ({
    toggleDrawer: open => dispatch(toggleDrawer(open)),
    toggleWindow: id => dispatch(toggleWindow(id)),
  })
)(Layout)
