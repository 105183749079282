import React from "react"
import styled from "@emotion/styled"
import ThemeContext from "../context/theme-context"
import { connect } from "react-redux"

import { toggleDarkMode } from "../state/app"

const LightSwitch = ({ isDarkMode, dispatch }) => (
  <div
    className="nav-primary"
    style={isDarkMode ? { opacity: 1 } : { opacity: 0.6 }}
    onClick={() => dispatch(toggleDarkMode(!isDarkMode))}
  >
    Dark Mode
  </div>
)

export default connect(
  state => ({
    isDarkMode: state.app.isDarkMode,
  }),
  null
)(LightSwitch)
