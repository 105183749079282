import React from "react"
import styled from "@emotion/styled"

const ShortcutList = props => (
  <ShortcutListContent>{props.children}</ShortcutListContent>
)

const ShortcutListContent = styled.div`
  display: flex;
  flex-direction: row;
`
export default ShortcutList
