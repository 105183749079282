import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import ThemeContext from "../context/theme-context"
import Icon from "./icon"
import PropTypes from "prop-types"
import { Rnd } from "react-rnd"
import Button from "../components/button"
import WindowClose from "./window-close"
import MediaQuery from "react-responsive"

// Taken from stackoverflow. May not work.
// https://stackoverflow.com/questions/1118198/how-can-you-figure-out-the-highest-z-index-in-your-document
function findHighestZIndex(elem) {
  var elems = document.getElementsByClassName(elem)
  var highest = 0
  for (var i = 0; i < elems.length; i++) {
    var zindex = document.defaultView
      .getComputedStyle(elems[i], null)
      .getPropertyValue("z-index")
    if (zindex > highest && zindex != "auto") {
      highest = zindex
    }
  }
  return parseInt(highest)
}

function checkLocalStorage(key, defaultValue) {
  if (localStorage.getItem(key) === null) {
    return defaultValue
  } else {
    return JSON.parse(localStorage.getItem(key))
  }
}

function setLocalStorage(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch (e) {
    return false
  }
}

function Window(props) {
  let [open, setOpen] = useState(props.open)

  let [z, setZ] = useState(props.z)
  let [x, setX] = useState(props.x)
  let [y, setY] = useState(props.y)
  let [width, setWidth] = useState(props.width)
  let [height, setHeight] = useState(props.height)

  let { location, title, children } = props
  let rootPath = `${__PATH_PREFIX__}/`
  let blogPath = `${__PATH_PREFIX__}/blog/`
  let header

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    setOpen(props.open)
    // setX(checkLocalStorage(props.id + "_x", props.x))
    // setY(checkLocalStorage(props.id + "_y", props.y))
    // setZ(checkLocalStorage(props.id + "_z", props.z))
    // setWidth(checkLocalStorage(props.id + "_width", props.width))
    // setHeight(checkLocalStorage(props.id + "_height", props.height))
    console.log("It Updated")
  })

  const onWindowClick = () => {
    const highZ = findHighestZIndex("react-draggable") + 1
    setLocalStorage(props.id + "_z", highZ)
    setZ(highZ)
  }

  const onWindowResize = (e, direction, ref, delta, position) => {
    const roundX = Math.round(position.x / 16) * 16
    const roundY = Math.round(position.y / 16) * 16
    const roundWidth = Math.round(ref.clientWidth / 16) * 16
    const roundHeight = Math.round(ref.clientHeight / 16) * 16
    setLocalStorage(props.id + "_x", roundX)
    setLocalStorage(props.id + "_y", roundY)
    setLocalStorage(props.id + "_width", roundWidth)
    setLocalStorage(props.id + "_height", roundHeight)
    setX(roundX)
    setY(roundY)
    setWidth(roundWidth)
    setHeight(roundHeight)
  }
  const onWindowDrag = (e, d) => {
    const roundX = Math.round(d.x / 16) * 16
    const roundY = Math.round(d.y / 16) * 16
    setLocalStorage(props.id + "_x", roundX)
    setLocalStorage(props.id + "_y", roundY)
    setX(roundX)
    setY(roundY)
  }
  const onRWindowToggle = () => {}
  const onRWindowOpen = () => {}
  const onRWindowClose = () => {}

  return (
    <ThemeContext.Consumer>
      {theme =>
        open && (
          <React.Fragment>
            <MediaQuery minWidth={720}>
              <Rnd
                dragHandleClassName="header-handle"
                default={{
                  x: x,
                  y: y,
                  width: width,
                  height: height,
                }}
                size={{ width: width, height: height }}
                position={{ x: x, y: y }}
                onDragStop={(e, d) => onWindowDrag(e, d)}
                onResizeStop={(e, direction, ref, delta, position) =>
                  onWindowResize(e, direction, ref, delta, position)
                }
                onMouseDown={() => onWindowClick()}
                resizeGrid={[theme.spacer * 2, theme.spacer * 2]}
                dragGrid={[theme.spacer * 2, theme.spacer * 2]}
                style={{ zIndex: z, visibility: open ? "visible" : "hidden" }}
              >
                <Container theme={theme}>
                  <Header theme={theme}>
                    <div className="title header-handle">{title}</div>
                    <div className="actions">
                      <div className="action-item">
                        <Icon
                          size={theme.spacer}
                          color={theme.foregroundColor[6]}
                        >
                          window_minimize
                        </Icon>
                      </div>
                      <div className="action-item">
                        <Icon
                          size={theme.spacer}
                          color={theme.foregroundColor[6]}
                        >
                          window_maximize
                        </Icon>
                      </div>
                      <WindowClose className="action-item" id={props.id}>
                        <Icon
                          size={theme.spacer}
                          color={theme.foregroundColor[6]}
                        >
                          close
                        </Icon>
                      </WindowClose>
                    </div>
                  </Header>
                  <Content theme={theme}>{children}</Content>
                </Container>
              </Rnd>
            </MediaQuery>
            <MediaQuery maxWidth={720}>
              <Container theme={theme}>
                <Header theme={theme}>
                  <div className="title header-handle">
                    {title}
                    &nbsp;-&nbsp;{open ? "active" : "not active"}
                  </div>
                  <div className="actions">
                    <div className="action-item">
                      <Icon
                        size={theme.spacer}
                        color={theme.foregroundColor[6]}
                      >
                        window_minimize
                      </Icon>
                    </div>
                    <div className="action-item">
                      <Icon
                        size={theme.spacer}
                        color={theme.foregroundColor[6]}
                      >
                        window_maximize
                      </Icon>
                    </div>
                    <WindowClose className="action-item" id={props.id}>
                      <Icon
                        size={theme.spacer}
                        color={theme.foregroundColor[6]}
                      >
                        close
                      </Icon>
                    </WindowClose>
                  </div>
                </Header>
                <Content theme={theme}>{children}</Content>
              </Container>
            </MediaQuery>
          </React.Fragment>
        )
      }
    </ThemeContext.Consumer>
  )
}

const Container = styled.div`
  background: ${props => props.theme.backgroundColor[3]};
  box-shadow: ${props => props.theme.spacer * 1 + props.theme.unit}
    ${props => props.theme.spacer * 1 + props.theme.unit} 0
    ${props => props.theme.backgroundColor[0]};
  display: flex;
  flex-direction: column;
  @media (min-width: 720px) {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
`

const Header = styled.div`
  background: ${props => props.theme.backgroundColor[6]};
  color: ${props => props.theme.primaryColor[6]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: stretch;
  min-height: ${props => props.theme.spacer * 4 + props.theme.unit};
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${props => props.theme.spacer * 0.5 + props.theme.unit}
      ${props => props.theme.spacer * 2 + props.theme.unit};
    flex-grow: 1;
    min-height: ${props => props.theme.spacer * 3 + props.theme.unit};
  }
  .actions {
    display: flex;
  }
  .action-item {
    width: ${props => props.theme.spacer * 4 + props.theme.unit};
    height: ${props => props.theme.spacer * 4 + props.theme.unit};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.primaryColor[6]};
    cursor: pointer;
    &:hover {
      background: ${props => props.theme.backgroundColor[15]};
      color: ${props => props.theme.primaryColor[15]};
    }
  }
  @media (min-width: 720px) {
    .header-handle {
      cursor: move;
    }
  }
`

const Content = styled.div`
  overflow-x: auto;
  padding: ${props => props.theme.spacer * 2 + props.theme.unit}
    ${props => props.theme.spacer * 2 + props.theme.unit};

  // Scrollbar
  &::-webkit-scrollbar {
    width: ${props => props.theme.spacer * 1 + props.theme.unit};
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: ${props => props.theme.primaryColor[6]}
      ${props => props.theme.backgroundColor[15]};
  }
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.backgroundColor[15]};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.primaryColor[6]};
  }

  @media (min-width: 720px) {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
`
export default Window

Window.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  z: PropTypes.number,
}

Window.defaultProps = {
  open: false,
  title: "",
  id: Math.floor(100000 + Math.random() * 900000),
  x: "50",
  y: "50",
  width: "500",
  height: "500",
  z: "1000",
}
