import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import ThemeContext from "../context/theme-context"

function Notices() {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <StaticQuery
          query={noticeQuery}
          render={data =>
            data.allFile.edges.map(({ node }, index) => {
              const title =
                node.childMdx.frontmatter.title || node.childMdx.fields.slug
              return (
                node.childMdx.frontmatter.alert_type !== "hidden" && (
                  <div key={node.childMdx.fields.slug}>
                    <Container theme={theme}>
                      {node.childMdx.frontmatter.alert_type === "danger" && (
                        <div className="alert-type">
                          <span>f</span>
                          {node.childMdx.frontmatter.alert_type.toUpperCase()}
                        </div>
                      )}
                      {node.childMdx.frontmatter.alert_type === "warning" && (
                        <div className="alert-type">
                          <span>f</span>
                          {node.childMdx.frontmatter.alert_type.toUpperCase()}
                        </div>
                      )}
                      {node.childMdx.frontmatter.alert_type === "notice" && (
                        <div className="alert-type">
                          <span>x</span>
                          {node.childMdx.frontmatter.alert_type.toUpperCase()}
                        </div>
                      )}
                      <div className="alert-text">
                        <h4>{title}</h4>
                        <p>{node.childMdx.frontmatter.description}</p>
                      </div>
                    </Container>
                  </div>
                )
              )
            })
          }
        />
      )}
    </ThemeContext.Consumer>
  )
}

export const noticeQuery = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "notice" } }
      sort: { fields: [childMdx___frontmatter___date], order: DESC }
      limit: 10
      skip: 0
    ) {
      edges {
        node {
          childMdx {
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              description
              alert_type
            }
          }
        }
      }
    }
  }
`

const Container = styled.div`
  position: relative;
  top: ${props => props.theme.spacer * 4 + props.theme.unit};
  background: ${props => props.theme.backgroundColor[3]};
  box-shadow: ${props => props.theme.spacer * 1 + props.theme.unit}
    ${props => props.theme.spacer * 1 + props.theme.unit} 0
    ${props => props.theme.backgroundColor[0]};
  padding-left: ${props => props.theme.spacer * 1 + props.theme.unit};
  padding-right: ${props => props.theme.border * 2 + props.theme.unit};
  :after {
    content: " ";
    position: absolute;
    top: ${props => props.theme.border * 2 + props.theme.unit};
    left: ${props => props.theme.border * 2 + props.theme.unit};
    bottom: ${props => props.theme.border * 2 + props.theme.unit};
    width: ${props => props.theme.spacer * 1 - 2 + props.theme.unit};
    background: repeating-linear-gradient(
      45deg,
      ${props => props.theme.backgroundColor[0]},
      ${props => props.theme.backgroundColor[0]}
        ${props => props.theme.spacer * 0.5 + props.theme.unit},
      ${props => props.theme.primaryColor[6]}
        ${props => props.theme.spacer * 0.5 + props.theme.unit},
      ${props => props.theme.primaryColor[6]}
        ${props => props.theme.spacer * 1 + props.theme.unit}
    );
  }
  .alert-type {
    font-family: "04b_21";
    font-weight: bold;
    span {
      text-align: center;
      display: inline-block;
      width: ${props => props.theme.spacer * 3 + props.theme.unit};
    }
  }
  .alert-text {
    margin-left: ${props => props.theme.spacer * 3 + props.theme.unit};
    h4 {
      margin: 0;
      color: ${props => props.theme.backgroundColor[8]};
      background: ${props => props.theme.primaryColor[6]};
      font-weight: bold;
      display: inline-block;
    }
    p {
      margin: 0;
    }
  }
  @media (min-width: 720px) {
    top: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    max-width: ${props => props.theme.spacer * 32 + props.theme.unit};
  }
`

export default Notices
