import React from "react"
import styled from "@emotion/styled"
import ThemeContext from "../context/theme-context"

import { graphql } from "gatsby"

import SEO from "./seo"

class TypeString extends React.Component {
  render() {
    return (
      <div>
        font-name: "{this.props.fontName}"
        <br />
        pixel-size: {this.props.pixelSize}
        <div
          className={"font-" + this.props.fontName}
          style={{ fontSize: this.props.pixelSize * 4 + "px" }}
        >
          ABCDEFGHIJKLMNOPQRSTUVWXYZ
          <br />
          abcdefghijklmnopqrstuvwxyz
          <br />
          1234567890
          <br />
          {
            " \x21\x22\x23\x24\x25\x26\x27\x28\x29\x2a\x2b\x2c\x2d\x2e\x2f\x3a\x3b\x3c\x3d\x3e\x3f\x40\x5b\x5c\x5d\x5e\x5f\x60\x7b\x7c\x7d\x7e"
          }
        </div>
      </div>
    )
  }
}

class TestPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <ThemeContext.Consumer>
        {theme => (
          <div>
            <SEO title="Test" />
            <h1>Test</h1>

            <TypeTest theme={theme}>
              <TypeString pixelSize="8" fontName="04b_03" />
              <TypeString pixelSize="8" fontName="04b_03b" />
              <TypeString pixelSize="8" fontName="04b_08" />
              <TypeString pixelSize="8" fontName="04b_09" />
              <TypeString pixelSize="8" fontName="04b_11" />
              <TypeString pixelSize="14" fontName="04b_19" />
              <TypeString pixelSize="8" fontName="04b_20" />
              <TypeString pixelSize="8" fontName="04b_21" />
              <TypeString pixelSize="8" fontName="04b_24" />
              <TypeString pixelSize="12" fontName="04b_25" />
              <TypeString pixelSize="12" fontName="04b_31" />
              <TypeString pixelSize="8" fontName="Handy" />
            </TypeTest>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

const TypeTest = styled.div`
  > div {
    margin-bottom: ${props => props.theme.spacer * 9 + props.theme.unit};
  }
  .font-04b_03 {
    font-family: "04b_03";
  }
  .font-04b_31 {
    font-family: "04b_31";
  }
  .font-04b_25 {
    font-family: "04b_25";
  }
  .font-04b_24 {
    font-family: "04b_24";
  }
  .font-04b_21 {
    font-family: "04b_21";
  }
  .font-04b_20 {
    font-family: "04b_20";
  }
  .font-04b_19 {
    font-family: "04b_19";
  }
  .font-04b_11 {
    font-family: "04b_11";
  }
  .font-04b_09 {
    font-family: "04b_09";
  }
  .font-04b_08 {
    font-family: "04b_08";
  }
  .font-04b_03b {
    font-family: "04b_03b";
  }
  .font-04b03rev {
    font-family: "04b03rev";
  }
  .font-Handy {
    font-family: "Handy";
  }
`

export default TestPage
