const initialState = {
  isDarkMode: false,
  isDrawerOpen: false,
  windows: [
    { id: "Braxtronix", active: true },
    { id: "Test", active: false },
    { id: "TypeTest", active: false },
    { id: "Chess", active: false },
  ],
}

const TOGGLE_WINDOW = "TOGGLE_WINDOW"
export const toggleWindow = id => ({
  type: "TOGGLE_WINDOW",
  id,
})

const TOGGLE_DARKMODE = "TOGGLE_DARKMODE"

export const toggleDarkMode = isDarkMode => ({
  type: TOGGLE_DARKMODE,
  payload: isDarkMode,
})

const TOGGLE_DRAWER = "TOGGLE_DRAWER"
export const toggleDrawer = open => ({
  type: TOGGLE_DRAWER,
  payload: open,
})

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_DARKMODE:
      return { ...state, isDarkMode: payload }
    case TOGGLE_DRAWER:
      return { ...state, isDrawerOpen: payload }
    // case TOGGLE_WINDOW:
    //   return {
    //     ...state,
    //     windows: [...state.windows, { name: action.id, active: false }],
    //   }
    case TOGGLE_WINDOW:
      return Object.assign({}, state, {
        windows: state.windows.map(window => {
          return window.id === action.id
            ? Object.assign({}, window, { active: !window.active })
            : window
        }),
      })

    default:
      return state
  }
}
