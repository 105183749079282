import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import ThemeContext from "../context/theme-context"
import PropTypes from "prop-types"

const Icon = props => {
  let locationX = 0
  let locationY = 0
  if (props.children === "close") {
    locationX = 0
    locationY = 0
  }
  if (props.children === "window_minimize") {
    locationX = 0
    locationY = 1
  }
  if (props.children === "window_restore") {
    locationX = 0
    locationY = 2
  }
  if (props.children === "window_maximize") {
    locationX = 0
    locationY = 3
  }

  if (props.children === "arrow_upright") {
    locationX = 1
    locationY = 0
  }
  if (props.children === "arrow_downright") {
    locationX = 1
    locationY = 1
  }
  if (props.children === "arrow_downleft") {
    locationX = 1
    locationY = 2
  }
  if (props.children === "arrow_upleft") {
    locationX = 1
    locationY = 3
  }

  if (props.children === "arrow_up") {
    locationX = 2
    locationY = 0
  }
  if (props.children === "arrow_right") {
    locationX = 2
    locationY = 1
  }
  if (props.children === "arrow_down") {
    locationX = 2
    locationY = 2
  }
  if (props.children === "arrow_left") {
    locationX = 2
    locationY = 3
  }

  if (props.children === "caret_up") {
    locationX = 3
    locationY = 0
  }
  if (props.children === "caret_right") {
    locationX = 3
    locationY = 1
  }
  if (props.children === "caret_down") {
    locationX = 3
    locationY = 2
  }
  if (props.children === "caret_left") {
    locationX = 3
    locationY = 3
  }

  if (props.children === "chess_rook") {
    locationX = 4
    locationY = 0
  }
  if (props.children === "chess_knight") {
    locationX = 4
    locationY = 1
  }
  if (props.children === "chess_bishop") {
    locationX = 4
    locationY = 2
  }
  if (props.children === "chess_king") {
    locationX = 4
    locationY = 3
  }
  if (props.children === "chess_queen") {
    locationX = 4
    locationY = 4
  }
  if (props.children === "chess_pawn") {
    locationX = 4
    locationY = 5
  }

  // Origin sheet size
  const spriteW = 76
  const spriteH = 64

  // Origin icon size
  const iconW = 8
  const iconH = 8

  // Adjusted icon size
  const sheetW = spriteW * (props.size / iconW) // Sprite width * multiplier
  const sheetH = spriteH * (props.size / iconH) // Sprite width * multiplier

  // Move icon position
  const sheetX = -(4 + locationX * (iconW + 4)) * (props.size / iconW) // Sprite width * multiplier
  const sheetY = -(4 + locationY * (iconW + 4)) * (props.size / iconH) // Sprite width * multiplier

  return (
    <ThemeContext.Consumer>
      {theme => (
        <Viewport
          theme={theme}
          size={props.size}
          sheetW={sheetW}
          sheetH={sheetH}
          sheetX={sheetX}
          sheetY={sheetY}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${spriteW} ${spriteH}`}
          >
            <title>{props.children}</title>
            <polygon
              style={{ fill: props.color }}
              points="70,59 70,58 69,58 69,57 70,57 70,56 70,55 69,55 69,54 68,54 67,54 67,55 66,55 66,56 66,57 67,57 67,58 66,58 
	66,59 65,59 65,60 66,60 67,60 68,60 69,60 70,60 71,60 71,59 "
            />
            <polygon
              style={{ fill: props.color }}
              points="58,57 58,56 58,55 59,55 59,54 58,54 58,53 57,53 57,52 56,52 55,52 55,53 54,53 54,54 53,54 53,55 54,55 54,56 
	54,57 54,57.9 54,58 54,59 53,59 53,60 54,60 55,60 56,60 57,60 58,60 59,60 59,59 58,59 58,58 "
            />
            <polygon
              style={{ fill: props.color }}
              points="46,57 46,56 47,56 47,55 46,55 45,55 45,54 46,54 46,53 45,53 45,52 44,52 43,52 43,53 42,53 42,54 43,54 43,55 
	42,55 41,55 41,56 42,56 42,57 42,58 42,59 41,59 41,60 42,60 43,60 44,60 45,60 46,60 47,60 47,59 46,59 46,58 "
            />
            <path
              style={{ fill: props.color }}
              d="M35,59v1h-1h-1h-1h-1h-1h-1v-1h1v-1h1v-1h-1v-1v-1h1v1h1v1h1v1h1v1H35z M32,56h1v1h1v-1v-1h-1v-1h-1v-1h-1v1v1h1V56z"
            />
            <path
              style={{ fill: props.color }}
              d="M21,57v1h1v1h1v1h-1h-1h-1h-1h-1h-1v-1h1v-1h1v-1h-1v-1h-1v-1v-1h1v-1h1h1h1v1h-1v1h1v-1h1v1h1v1h-1h-1V57z"
            />
            <path
              style={{ fill: props.color }}
              d="M10,53v1H9v-1H8H7v1H6v-1H5v1v1h1v1v1v1v1H5v1h1h1h1h1h1h1v-1h-1v-1v-1v-1v-1h1v-1v-1H10z M9,58v1H8H7v-1v-1h1h1V58z"
            />

            <path style={{ fill: props.color }} d="M64,40v8h8v-8H64z" />
            <path style={{ fill: props.color }} d="M52,40v8h8v-8H52z" />
            <polygon
              style={{ fill: props.color }}
              points="46,43 46,40 45,40 45,41 44,41 44,42 43,42 43,43 42,43 42,45 43,45 43,46 44,46 44,47 45,47 45,48 46,48 46,45 "
            />
            <polygon
              style={{ fill: props.color }}
              points="31,42 28,42 28,43 29,43 29,44 30,44 30,45 31,45 31,46 33,46 33,45 34,45 34,44 35,44 35,43 36,43 36,42 33,42 "
            />
            <polygon
              style={{ fill: props.color }}
              points="18,45 18,48 19,48 19,47 20,47 20,46 21,46 21,45 22,45 22,43 21,43 21,42 20,42 20,41 19,41 19,40 18,40 18,43 "
            />
            <polygon
              style={{ fill: props.color }}
              points="9,46 12,46 12,45 11,45 11,44 10,44 10,43 9,43 9,42 7,42 7,43 6,43 6,44 5,44 5,45 4,45 4,46 7,46 "
            />
            <path style={{ fill: props.color }} d="M64,28v8h8v-8H64z" />
            <path style={{ fill: props.color }} d="M52,28v8h8v-8H52z" />
            <polygon
              style={{ fill: props.color }}
              points="40,33 41,33 41,34 42,34 42,35 43,35 43,36 44,36 44,33 48,33 48,31 44,31 44,28 43,28 43,29 42,29 42,30 41,30 
	41,31 40,31 "
            />
            <polygon
              style={{ fill: props.color }}
              points="33,36 33,35 34,35 34,34 35,34 35,33 36,33 36,32 33,32 33,28 31,28 31,32 28,32 28,33 29,33 29,34 30,34 30,35 
	31,35 31,36 "
            />
            <polygon
              style={{ fill: props.color }}
              points="24,31 23,31 23,30 22,30 22,29 21,29 21,28 20,28 20,31 16,31 16,33 20,33 20,36 21,36 21,35 22,35 22,34 23,34 
	23,33 24,33 "
            />
            <polygon
              style={{ fill: props.color }}
              points="7,28 7,29 6,29 6,30 5,30 5,31 4,31 4,32 7,32 7,36 9,36 9,32 12,32 12,31 11,31 11,30 10,30 10,29 9,29 9,28 "
            />
            <path style={{ fill: props.color }} d="M64,16v8h8v-8H64z" />
            <path style={{ fill: props.color }} d="M52,16v8h8v-8H52z" />
            <polygon
              style={{ fill: props.color }}
              points="47,22 47,21 46,21 46,20 45,20 45,19 44,19 44,18 45,18 45,17 46,17 46,16 45,16 40,16 40,17 40,18 40,19 40,20 
	40,21 40,22 41,22 41,21 42,21 42,20 43,20 43,21 44,21 44,22 45,22 45,23 46,23 46,24 48,24 48,22 "
            />
            <polygon
              style={{ fill: props.color }}
              points="34,16 34,17 33,17 33,18 32,18 32,19 31,19 31,20 30,20 30,19 29,19 29,18 28,18 28,19 28,24 29,24 30,24 31,24 
	32,24 33,24 34,24 34,23 33,23 33,22 32,22 32,21 33,21 33,20 34,20 34,19 35,19 35,18 36,18 36,16 "
            />
            <polygon
              style={{ fill: props.color }}
              points="23,18 23,19 22,19 22,20 21,20 21,19 20,19 20,18 19,18 19,17 18,17 18,16 16,16 16,18 17,18 17,19 18,19 18,20 
	19,20 19,21 20,21 20,22 19,22 19,23 18,23 18,24 19,24 24,24 24,23 24,22 24,21 24,20 24,19 24,18 "
            />
            <polygon
              style={{ fill: props.color }}
              points="11,16 10,16 9,16 8,16 7,16 6,16 6,17 7,17 7,18 8,18 8,19 7,19 7,20 6,20 6,21 5,21 5,22 4,22 4,24 6,24 6,23 
	7,23 7,22 8,22 8,21 9,21 9,20 10,20 10,21 11,21 11,22 12,22 12,21 12,16 "
            />
            <path style={{ fill: props.color }} d="M64,4v8h8V4H64z" />
            <path style={{ fill: props.color }} d="M52,4v8h8V4H52z" />
            <path
              style={{ fill: props.color }}
              d="M40,4v8h8V4H40z M47,11h-6V7h6V11z M47,6h-6V5h6V6z"
            />
            <path
              style={{ fill: props.color }}
              d="M34,7V4h-6v6h6V7z M29,5h4v1h-4V5z M29,9V7h4v2H29z M36,6v6h-6v-1h5V6H36z"
            />
            <path
              style={{ fill: props.color }}
              d="M16,4v3h8V4H16z M23,6h-6V5h6V6z"
            />
            <polygon
              style={{ fill: props.color }}
              points="10,7 10,6 11,6 11,5 12,5 12,4 11,4 10,4 10,5 9,5 9,6 8,6 7,6 7,5 6,5 6,4 5,4 4,4 4,5 5,5 5,6 6,6 6,7 7,7 7,9 
	6,9 6,10 5,10 5,11 4,11 4,12 5,12 6,12 6,11 7,11 7,10 8,10 9,10 9,11 10,11 10,12 11,12 12,12 12,11 11,11 11,10 10,10 10,9 9,9 
	9,7 "
            />
          </svg>
        </Viewport>
      )}
    </ThemeContext.Consumer>
  )
}
export default Icon

const Viewport = styled.div`
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: ${props => props.size + props.theme.unit};
  height: ${props => props.size + props.theme.unit};
  svg {
    position: absolute;
    top: ${props => props.sheetX + props.theme.unit};
    left: ${props => props.sheetY + props.theme.unit};
    width: ${props => props.sheetW + props.theme.unit};
    height: ${props => props.sheetH + props.theme.unit};
  }
`

Icon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Icon.defaultProps = {
  size: 16,
  color: "#ffffff",
}
