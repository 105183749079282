import React from "react"
import styled from "@emotion/styled"
import ThemeContext from "../context/theme-context"

const Button = props => (
  <ThemeContext.Consumer>
    {theme => (
      <ButtonWrapper theme={theme} props={props}>
        {props.children}
      </ButtonWrapper>
    )}
  </ThemeContext.Consumer>
)

const ButtonWrapper = styled.button`
  display: block;
  border: none;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "${props => props.theme.fontFamilyRegular}";
  padding: ${props => props.theme.spacer * 1 + props.theme.unit}
    ${props => props.theme.spacer * 2 + props.theme.unit};
  cursor: pointer;
  text-transform: uppercase;
  color: ${props => props.theme.backgroundColor[8]};
  background: ${props => props.theme.primaryColor[6]};
  font-size: ${props => props.theme.fontSize * 1 + props.theme.unit};
  line-height: ${props => props.theme.lineHeight};
  font-weight: bold;
  border-radius: 0;
  &:hover {
    background: ${props => props.theme.primaryColor[9]};
  }
`

export default Button
